import {displayFlashMessenger} from "../../mcrm/messenger";

(() => {
  const flash_data_el = document.getElementById("flash_data");
  if (!flash_data_el) return;
  const flash_data = JSON.parse(flash_data_el.innerHTML);

  for (let flash_type in flash_data) {
    if (!flash_data.hasOwnProperty(flash_type)) continue;

    let flashType;
    if (flash_type === 'alert') {
      flashType = 'danger';
    }
    else if (flash_type === 'notice') {
      flashType = 'info';
    }
    else if (flash_type === 'splash') {
      flashType = 'success';
    }
    else {
      flashType = 'info';
    }

    const attr = flash_data[flash_type];
    for (let j = 0; j < attr.length; j++) {
      displayFlashMessenger(attr[j], flashType);
    }
  }
})();
